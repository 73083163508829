import React, {Component} from 'react';
import HeaderImoveis from './HeaderImoveis';

class Contato extends Component {
    render() {
        const generalContatoStyle = {
            color: "rgb(28, 89, 207)",
            justifyContent: "center",
            margin: "25px"
        }

        return (<div>
            <HeaderImoveis/>
            <h3 style={generalContatoStyle}> Telefone: (48) 3254 - 4000</h3>
            <h3 style={generalContatoStyle}> Plantão: (48) 99945 - 4177</h3>
        </div>)
    }
}

export default Contato;
