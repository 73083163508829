const Imoveis = [
     {
        id: 1,
        titulo: "Casa frente mar",
        endereco: "Rua Giovane Luiz Piucco",
        tamanho_imovel: "-",
        vagas: 1,
        quartos: 4,
        banheiros: 2,
        media: [
            {
                source: "/imgs/imoveis/1/A_1.jpg"
            }, {
                source: "/imgs/imoveis/1/A_2.jpg"
            }, {
                source: "/imgs/imoveis/1/A_3.jpeg"
            }, {
                source: "/imgs/imoveis/1/A_4.jpeg"
            }
        ]
    },
    {
        id: 2,
        titulo: "Abacateiro Cincoenta e Cinco - Unidades de 80 a 123 m²",
        endereco: "Rua 30 de Dezembro, nº 55, no centro de Garopaba",
        tamanho_imovel: "80",
        vagas: "-",
        quartos: "2",
        banheiros: "-",
        media: [
            {
                source: "/imgs/imoveis/2/L_2.jpg"
            },{
                source: "/imgs/imoveis/2/L_3.jpg"
            },{
                source: "/imgs/imoveis/2/L_4.jpg"
            },{
                source: "/imgs/imoveis/2/L_5.jpg"
            },{
                source: "/imgs/imoveis/2/L_6.jpg"
            },{
                source: "/imgs/imoveis/2/L_7.jpg"
            },{
                source: "/imgs/imoveis/2/L_8.jpg"
            },{
                source: "/imgs/imoveis/2/L_9.jpg"
            },{
                source: "/imgs/imoveis/2/L_10.jpg"
            },{
                source: "/imgs/imoveis/2/L_11.jpg"
            },{
                source: "/imgs/imoveis/2/L_12.jpg"
            },{
                source: "/imgs/imoveis/2/L_13.jpg"
            },{
                source: "/imgs/imoveis/2/L_14.jpg"
            },{
                source: "/imgs/imoveis/2/L_15.jpg"
            }
        ]
    },
    {
        id: 3,
        titulo: "Terreno no Bairro Ferraz 700m²",
        endereco: "Rua GRP 010, Ferraz",
        tamanho_imovel: "700",
        vagas: "-",
        quartos: "-",
        banheiros: "-",
        media: [
            {
                source: "/imgs/imoveis/3/B_1.jpg"
            },{
                source: "/imgs/imoveis/3/B_2.jpg"
            },{
                source: "/imgs/imoveis/3/B_3.jpg"
            },{
                source: "/imgs/imoveis/3/B_4.jpg"
            },{
                source: "/imgs/imoveis/3/B_5.jpg"
            },
        ]
    },
    {
        id: 4,
        titulo: "Terreno Vigia",
        endereco: "Loteamento novo Vigia",
        tamanho_imovel: "-",
        vagas: "-",
        quartos: "-",
        banheiros: "-",
        media: [
            {
                source: "/imgs/imoveis/4/C_1.jpg"
            }
        ]
    },
]
export default Imoveis;
