import React, {Component} from 'react';
import Blog from '../components/Blog';

class MainBlog extends Component {
    render() {
        return (<div>
            <Blog/>
        </div>);
    }
}

export default MainBlog;
