import React, {Component} from 'react';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import HotelRoundedIcon from '@material-ui/icons/HotelRounded';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HeightTwoToneIcon from '@material-ui/icons/HeightTwoTone';
import MediaQuery from 'react-responsive';


class InfoCadaImovel extends Component {
    constructor(props){
        super(props);

        this.state = {
            imovel: this.props.imovelParam
        }
    }

render() {
    /* ESTILO 320px a 480px */

    const infoStyleMax480 = {
        display: "flex",
        flexFlow: "row",
        height: "auto",
        width: "20vw",
        margin: "1vh auto auto auto",
        padding: "auto",
        borderRadius: "10px",
        fontSize: "13px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center"
    }

    const spanStyleMax480 = {
        display: "flex",
        flexFlow: "column",
        margin: "5px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "8vw",
        textAlign: "center",
        alignItems: "center",
        height: "11vh",
        padding: "10px",
        justifyContent: "center"
    }
    const imovelTitleMax480 = {
        display: "flex",
        flexFlow: "column",
        margin: "7vh auto auto auto",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "65vw",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "12vh",
        backgroundColor: "rgb(217, 217, 217)",
        fontSize: "13px"
    }

    const locationTextMax480 = {
        fontSize: "11px"
    }

    /* ESTILO 481px a 768px */

    const infoStyleMax768 = {
        display: "flex",
        flexFlow: "row",
        width: "100%",
        height: "10vh",
        margin: "auto",
        padding: "auto",
        borderRadius: "10px",
        fontSize: "13px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center"

    }

    const spanStyleMax768 = {
        display: "flex",
        flexFlow: "column",
        margin: "4px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "100%",
        textAlign: "center",
        alignItems: "center",
        alignSelf: "center",
        padding: "6px",
        height: "8vh",
        fontSize: "16px",
        justifyContent: "center"
    }

    const imovelTitleMax768 = {
        display: "flex",
        flexFlow: "column",
        margin: "7vh 5px auto 5px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "60vw",
        textAlign: "center",
        justifyContent: "center",
        padding: "auto",
        height: "10vh",
        backgroundColor: "rgb(217, 217, 217)",
        fontSize: "20px"
    }

    const locationTextMax768 = {
        fontSize: "18px"
    }

    /* ESTILO 769px a 1024px */

    const infoStyleMax1024 = {
        display: "flex",
        flexFlow: "row",
        height: "auto",
        width: "100%",
        margin: "auto",
        padding: "auto",
        borderRadius: "10px",
        fontSize: "20px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center"
    }

    const spanStyleMax1024 = {
        display: "flex",
        flexFlow: "column",
        margin: "5px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "100%",
        textAlign: "center",
        alignItems: "center",
        height: "10vh",
        justifyContent: "center"
    }

    const imovelTitleMax1024 = {
        display: "flex",
        flexFlow: "column",
        margin: "6vh 5px auto 5px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "75vw",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "auto",
        height: "8vh",
        backgroundColor: "rgb(217, 217, 217)",
        fontSize: "22px"
    }
    const locationTextMax1024 = {
        fontSize: "20px"
    }

    /* ESTILO 1025px a 1200px */

    const infoStyleMax1200 = {
        display: "flex",
        flexFlow: "row",
        height: "auto",
        width: "15vw",
        margin: "0px auto 40vh auto",
        padding: "20px",
        borderRadius: "10px",
        fontSize: "20px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center"
    }

    const spanStyleMax1200 = {
        display: "flex",
        flexFlow: "column",
        margin: "10px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "10vw",
        textAlign: "center",
        alignItems: "center",
        height: "9vh",
        padding: "15px",
        justifyContent: "center"
    }
    const imovelTitleMax1200 = {
        display: "flex",
        flexFlow: "column",
        margin: "5vh auto auto auto",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "45vw",
        textAlign: "center",
        alignItems: "center",
        padding: "25px",
        height: "8vh",
        backgroundColor: "rgb(217, 217, 217)",
        fontSize: "20px"
    }
    const locationTextMax1200 = {
        fontSize: "15px"
    }

    /* ESTILO MIN 1201px */

    const infoStyleMin1201 = {
        display: "flex",
        flexFlow: "row",
        height: "auto",
        width: "15vw",
        margin: "auto",
        padding: "20px",
        borderRadius: "10px",
        fontSize: "13px",
        textAlign: "center",
        alignItems: "center"
    }

    const spanStyleMin1201 = {
        display: "flex",
        flexFlow: "column",
        margin: "1vh 5px auto 0px",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "6vw",
        textAlign: "center",
        alignItems: "center",
        height: "11vh",
        padding: "auto"
    }
    const imovelTitleMin1201 = {
        display: "flex",
        flexFlow: "column",
        margin: "7vh auto auto auto",
        borderRadius: "5px",
        border: "1px solid rgb(85, 85, 85)",
        width: "65vw",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "auto",
        height: "10vh",
        backgroundColor: "rgb(217, 217, 217)",
        fontSize: "19px"
    }
    const locationTextMin1201 = {
        fontSize: "16px"
    }

    return (<div>

        {/* ESTILO TELAS MAX 480px */}

        <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
            <p style={imovelTitleMax480}>{
                    this.state.imovel
                        ? this.state.imovel.titulo
                        : "Ops... ocorreu um erro"
                }
                <span style={locationTextMax480}><LocationOnIcon/>{this.state.imovel
                    ? this.state.imovel.endereco
                    : "Ops... ocorreu um erro"}</span>
            </p>
            <div id="infoImovel" style={infoStyleMax480}>
                <span style={spanStyleMax480}><BathtubOutlinedIcon/>
                    <h3>{this.state.imovel.banheiros}</h3>
                </span>
                <span style={spanStyleMax480}><HotelRoundedIcon/>
                    <h3>{this.state.imovel.quartos}</h3>
                </span>
                <span style={spanStyleMax480}><DirectionsCarRoundedIcon/>
                    <h3>{this.state.imovel.vagas}</h3>
                </span>
                <span style={spanStyleMax480}><HeightTwoToneIcon/>
                    <h5>{this.state.imovel.tamanho_imovel}</h5>
                </span>
            </div>
        </MediaQuery>

        {/* ESTILO TELAS MAX 768px */}

        <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
            <p style={imovelTitleMax768}>{
                    this.state.imovel
                        ? this.state.imovel.titulo
                        : "Ops... ocorreu um erro"
                }
                <span style={locationTextMax768}><LocationOnIcon/>{
                        this.state.imovel
                            ? this.state.imovel.endereco
                            : "Ops... ocorreu um erro"
                    }</span>
            </p>
            <div id="infoImovel" style={infoStyleMax768}>
                <span style={spanStyleMax768}><BathtubOutlinedIcon/>
                    <h2>{this.state.imovel.banheiros}</h2>
                </span>
                <span style={spanStyleMax768}><HotelRoundedIcon/>
                    <h2>{this.state.imovel.quartos}</h2>
                </span>
                <span style={spanStyleMax768}><DirectionsCarRoundedIcon/>
                    <h2>{this.state.imovel.vagas}</h2>
                </span>
                <span style={spanStyleMax768}><HeightTwoToneIcon/>
                    <h5>{this.state.imovel.tamanho_imovel}</h5>
                </span>
            </div>
        </MediaQuery>

        {/* FIM MAX 768px */}

        {/* INICIO MAX 1024px */}

        <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
            <p style={imovelTitleMax1024}>{
                    this.state.imovel
                        ? this.state.imovel.titulo
                        : "Ops... ocorreu um erro"
                }
                <span style={locationTextMax1024}><LocationOnIcon/>{this.state.imovel
                    ? this.state.imovel.endereco
                    : "Ops... ocorreu um erro"}</span>
            </p>
            <div id="infoImovel" style={infoStyleMax1024}>
                <span style={spanStyleMax1024}><BathtubOutlinedIcon/>
                    <h2>{this.state.imovel.banheiros}</h2>
                </span>
                <span style={spanStyleMax1024}><HotelRoundedIcon/>
                    <h2>{this.state.imovel.quartos}</h2>
                </span>
                <span style={spanStyleMax1024}><DirectionsCarRoundedIcon/>
                    <h2>{this.state.imovel.vagas}</h2>
                </span>
                <span style={spanStyleMax1024}><HeightTwoToneIcon/>
                    <h5>{this.state.imovel.tamanho_imovel}</h5>
                </span>
            </div>
        </MediaQuery>

        {/* FIM MAX 1024px */}

        {/* INICIO MAX 1200px */}

        <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
            <p style={imovelTitleMax1200}>{
                    this.state.imovel
                        ? this.state.imovel.titulo
                        : "Ops... ocorreu um erro"
                }
                <span style={locationTextMax1200}><LocationOnIcon/>{this.state.imovel
                    ? this.state.imovel.endereco
                    : "Ops... ocorreu um erro"}</span>
            </p>
            <div id="infoImovel" style={infoStyleMax1200}>
                <span style={spanStyleMax1200}><BathtubOutlinedIcon/>
                    <h2>{this.state.imovel.banheiros}</h2>
                </span>
                <span style={spanStyleMax1200}><HotelRoundedIcon/>
                    <h2>{this.state.imovel.quartos}</h2>
                </span>
                <span style={spanStyleMax1200}><DirectionsCarRoundedIcon/>
                    <h2>{this.state.imovel.vagas}</h2>
                </span>
                <span style={spanStyleMax1200}><HeightTwoToneIcon/>
                    <h5>{this.state.imovel.tamanho_imovel}</h5>
                </span>
            </div>
        </MediaQuery>

        {/* FIM MAX 1200px */}

        {/* INICIO MIN 1201px */}

        <MediaQuery minDeviceWidth={1201}>
            <p style={imovelTitleMin1201}>{
                    this.state.imovel
                        ? this.state.imovel.titulo
                        : "Ops... ocorreu um erro"
                }
                <span style={locationTextMin1201}><LocationOnIcon/>{this.state.imovel
                    ? this.state.imovel.endereco
                    : "Ops... ocorreu um erro"}</span>
            </p>
            <div id="infoImovel" style={infoStyleMin1201}>
                <span style={spanStyleMin1201}><BathtubOutlinedIcon/>
                    <h2>{this.state.imovel.banheiros}</h2>
                </span>
                <span style={spanStyleMin1201}><HotelRoundedIcon/>
                    <h2>{this.state.imovel.quartos}</h2>
                </span>
                <span style={spanStyleMin1201}><DirectionsCarRoundedIcon/>
                    <h2>{this.state.imovel.vagas}</h2>
                </span>
                <span style={spanStyleMin1201}><HeightTwoToneIcon/>
                    <h3>{this.state.imovel.tamanho_imovel}</h3>
                </span>
            </div>
        </MediaQuery>

    </div>)
}
}

export default InfoCadaImovel;
