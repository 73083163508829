import React, {Component} from 'react';
import PermIdentityTwoToneIcon from '@material-ui/icons/PermIdentityTwoTone';
import MediaQuery from 'react-responsive';

class Footer extends Component {
    render() {

        /*
            INICIO
        ESTILO MOBILE DEVICES DE 320px  A  480px
        */

        const footerMobileMax480Style = {
            border: "2px solid rgb(193, 193, 193)",
            borderRadius: "15px",
            margin: "auto",
            textAlign: "center",
            width: "90%",
            height: "auto",
            backgroundColor: "rgb(240, 240, 240)"
        }

        /*
                FIM MOBILE 320px A 480px

        */

        /*
                     INICIO
         ESTILO TABLETS DEVICES 481px  A 768px

         */

        const footerTabletsMax768Style = {
            border: "2px solid rgb(193, 193, 193)",
            borderRadius: "15px",
            margin: "auto",
            width: "90%",
            textAlign: "center",
            padding: "auto",
            backgroundColor: "rgb(240, 240, 240)"
        }

        /*
            FIM TABLET 768px
        */

        /*
                INICIO
        ESTILO TELAS PEQUENAS E LAPTOPS 769px  A  1024px
        */

        const footerMax1024Style = {
            border: "2px solid rgb(193, 193, 193)",
            borderRadius: "15px",
            margin: "auto",
            width: "90%",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "300",
            padding: "15px",
            backgroundColor: "rgb(240, 240, 240)"
        }

        /*
                    FIM TELAS MAX 1024px

            */

        /*
                INICIO
        ESTILO TELAS DESKTOP 1025px  A  1200px
            */

        const footerMax1200Style = {
            border: "2px solid rgb(193, 193, 193)",
            borderRadius: "15px",
            margin: "auto",
            width: "97%",
            textAlign: "center",
            backgroundColor: "rgb(240, 240, 240)"
        }

        /*
            FIM TELAS MAX 1200px
        */

        /*
                INICIO
        ESTILO TELAS DESKTOP MIN 1201px
            */

        const footerMin1201Style = {
            border: "2px solid rgb(193, 193, 193)",
            borderRadius: "15px",
            margin: "auto",
            textAlign: "center",
            width: "97%",
            height: "25%",
            backgroundColor: "rgb(240, 240, 240)"
        }

        return (<div>

            {/* MOBILE DEVICES DE 320 A 480px */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <div style={footerMobileMax480Style}>
                    <h6>Fale conosco
                        <PermIdentityTwoToneIcon/></h6>
                    <h6>Telefones: (48) 3254-4000 | (48) 99945-4177 | corretor.omar@gmail.com</h6>
                </div>
            </MediaQuery>

            {/* FIM MOBILE MAX 480px */}

            {/* INICIO TABLETS MAX 768px */}

            <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
                <div style={footerTabletsMax768Style}>
                    <h5>Fale conosco
                        <PermIdentityTwoToneIcon/></h5>
                    <h5>Telefones: (48) 3254-4000 | (48) 99945-4177 | corretor.omar@gmail.com</h5>
                </div>
            </MediaQuery>

            {/* FIM MAX 768px */}

            {/* INICIO TELAS MAX 1024px */}

            <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
                <div style={footerMax1024Style}>
                    <h5>Fale conosco
                        <PermIdentityTwoToneIcon/></h5>
                    <h5>Telefones: (48) 3254-4000 | (48) 99945-4177 | corretor.omar@gmail.com</h5>
                </div>
            </MediaQuery>

            {/* FIM MAX 1024px */}

            {/* INICIO TELAS MAX 1200px */}

            <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
                <div style={footerMax1200Style}>
                    <h5>Fale conosco
                        <PermIdentityTwoToneIcon/></h5>
                    <h5>Telefones: (48) 3254-4000 | (48) 99945-4177 | corretor.omar@gmail.com</h5>
                </div>
            </MediaQuery>

            {/* FIM MAX 1200px */}

            {/* INICIO TELAS MIN 1201px */}

            <MediaQuery minDeviceWidth={1201}>
                <div style={footerMin1201Style}>
                    <h4>Fale conosco
                        <PermIdentityTwoToneIcon/></h4>
                    <h4>Telefones: (48) 3254-4000 | (48) 99945-4177 | corretor.omar@gmail.com</h4>
                </div>
            </MediaQuery>

        </div>)
    }
}

export default Footer;
