import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import InfoIcon from '@material-ui/icons/Info';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import MediaQuery from 'react-responsive';

class Header extends Component {
    render() {

        /*
                    INICIO
        ESTILO MOBILE DEVICES DE 320px  A  480px

         */

        const buttonsStyleMobileMax480 = {
            padding: "auto",
            margin: "3px",
            width: "30%",
            height: "4vh",
            fontSize: "11px"
        };

        const headerComponentsMobileMax480 = {
            display: "flex",
            width: "auto",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /*
                    FIM MOBILE 320px A 480px

         */

        /*
                    INICIO
        ESTILO TABLETS DEVICES 481px  A 768px

        */

        const buttonsStyleTabletMax768 = {
            padding: "auto",
            margin: "5px",
            width: "25vw",
            fontSize: "20px"
        };

        const headerComponentsTabletMax768 = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /*
            FIM TABLET 768px

        */

        /*
                      INICIO
          ESTILO TELAS PEQUENAS E LAPTOPS 769px  A  1024px

          */

        const buttonsStyleLaptopMax1024 = {
            padding: "auto",
            margin: "5px",
            width: "35%",
            height: "4vh",
            fontSize: "25px"
        };

        const headerComponentsLaptopMax1024 = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /*
                    FIM TELAS MAX 1024px

           */

        /*
                        INICIO
            ESTILO TELAS DESKTOP 1025px  A  1200px

            */

        const buttonsStyleDesktopMax1200 = {
            padding: "auto",
            margin: "5px",
            width: "65%",
            height: "auto",
            fontSize: "25px"
        };

        const headerComponentsDesktopMax1200 = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /*
                        FIM TELAS MAX 1200px

             */

        /*
                          INICIO
              ESTILO TELAS DESKTOP MIN 1201px

              */

        const buttonsStyleDesktopMin1201 = {
            padding: "auto",
            margin: "10px",
            width: "75%",
            fontSize: "22px"
        };

        const headerComponentsDesktopMin1201 = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        return (<header className="header">

            {/* MOBILE DEVICES DE 320 A 480px */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <div className="header-buttons" style={headerComponentsMobileMax480}>
                    <Button style={buttonsStyleMobileMax480} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleMobileMax480} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleMobileMax480} variant="contained" color="primary" href="/sobre">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleMobileMax480} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </MediaQuery>

            {/*
                        FIM MOBILE 320px A 480px

             */
            }

            {/*
                         INICIO
             ESTILO TABLETS DEVICES 481px  A 768px

             */
            }

            <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
                <div className="header-buttons" style={headerComponentsTabletMax768}>
                    <Button style={buttonsStyleTabletMax768} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleTabletMax768} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleTabletMax768} variant="contained" color="primary" href="/sobre">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleTabletMax768} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </MediaQuery>

            {/*
                         FIM TABLETS 481px A 768px

              */
            }

            {/*
                                INICIO
                 ESTILO TELAS PEQUENAS E LAPTOPS 769px  A  1024px

             */
            }

            <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
                <div className="header-buttons" style={headerComponentsLaptopMax1024}>
                    <Button style={buttonsStyleLaptopMax1024} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleLaptopMax1024} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleLaptopMax1024} variant="contained" color="primary" href="/sobre">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleLaptopMax1024} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </MediaQuery>

            {/*
                         FIM TELAS MAX 1024px

              */
            }

            {/*
                                INICIO
                 ESTILO TELAS DESKTOP 1025px  A  1200px

             */
            }

            <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
                <div className="header-buttons" style={headerComponentsDesktopMax1200}>
                    <Button style={buttonsStyleDesktopMax1200} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleDesktopMax1200} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleDesktopMax1200} variant="contained" color="primary" href="/sobre">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleDesktopMax1200} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </MediaQuery>

            {/*
                         FIM TELAS MAX 1200px

              */
            }

            {/*
                                INICIO
                 ESTILO TELAS DESKTOP MIN  1201px

             */
            }

            <MediaQuery minDeviceWidth={1201}>
                <div className="header-buttons" style={headerComponentsDesktopMin1201}>
                    <Button style={buttonsStyleDesktopMin1201} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleDesktopMin1201} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleDesktopMin1201} variant="contained" color="primary" href="/sobre">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleDesktopMin1201} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </MediaQuery>

        </header>)

    }

}

export default Header;
