import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SearchIcon from '@material-ui/icons/Search';
import MediaQuery from 'react-responsive';

class HeaderBlog extends Component {
    render() {

        /* MIN 320 MAX 480px */

        const buttonsStyleMax480 = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            padding: "2px",
            margin: "5px",
            width: "auto",
            height: "auto",
            fontSize: "10px"
        };

        const headerComponentsMax480 = {
            display: "flex",
            flexFlow: "row",
            padding: "auto",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        const searchFieldMax480 = {
            margin: "auto 5px auto 5vw",
            width: "20vw"
        }

        /* MIN 481 MAX 768px */

        const buttonsStyleMax768 = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            padding: "2px",
            margin: "10px",
            width: "auto",
            height: "auto",
            fontSize: "18px"
        };

        const headerComponentsMax768 = {
            display: "flex",
            flexFlow: "row",
            padding: "auto",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        const searchFieldMax768 = {
            margin: "auto",
            width: "30vw"
        }

        /* MIN 769 MAX 1024px */

        const buttonsStyleMax1024 = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            padding: "5px",
            margin: "10px",
            width: "auto",
            height: "auto",
            fontSize: "20px"
        };

        const headerComponentsMax1024 = {
            display: "flex",
            flexFlow: "row",
            padding: "auto",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        const searchFieldMax1024 = {
            margin: "auto auto auto 20vw",
            width: "30vw"
        }

        /* MIN 1024 MAX 1200px */

        const buttonsStyleMax1200 = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            padding: "10px",
            margin: "15px",
            width: "auto",
            height: "auto",
            fontSize: "25px"
        };

        const headerComponentsMax1200 = {
            display: "flex",
            flexFlow: "row",
            padding: "auto",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        const searchFieldMax1200 = {
            margin: "auto 5px auto auto",
            padding: "10px",
            width: "35vw"
        }

        /* MIN 1201px */

        const buttonsStyleMin1201 = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            padding: "10px",
            margin: "15px",
            width: "10vw",
            height: "auto",
            fontSize: "20px"
        };

        const headerComponentsMin1201 = {
            display: "flex",
            padding: "auto",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        const searchFieldMin1201 = {
            margin: "auto 15px auto 25vw",
            padding: "5px",
            width: "35vw"
        }

        return (<div>

            {/* MIN 320 MAX 480px */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <header className="header">
                    <div className="header-buttons" style={headerComponentsMax480}>
                        <Button style={buttonsStyleMax480} variant="contained" color="primary" href="/">
                            <HomeIcon/>
                            Home
                        </Button>
                        <Button style={buttonsStyleMax480} variant="contained" color="primary" href="#">
                            <InfoIcon/>
                            Sobre
                        </Button>
                        <Button style={buttonsStyleMax480} variant="contained" color="primary" href="/contato">
                            <PermContactCalendarIcon/>
                            Contato
                        </Button>
                        <input type="search" style={searchFieldMax480}/>
                        <SearchIcon/>
                    </div>
                </header>
            </MediaQuery>

            {/* MIN 481 MAX 768px */}

            <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
                <header className="header">
                    <div className="header-buttons" style={headerComponentsMax768}>
                        <Button style={buttonsStyleMax768} variant="contained" color="primary" href="/">
                            <HomeIcon/>
                            Home
                        </Button>
                        <Button style={buttonsStyleMax768} variant="contained" color="primary" href="#">
                            <InfoIcon/>
                            Sobre
                        </Button>
                        <Button style={buttonsStyleMax768} variant="contained" color="primary" href="/contato">
                            <PermContactCalendarIcon/>
                            Contato
                        </Button>
                        <input type="search" style={searchFieldMax768}/>
                        <SearchIcon/>
                    </div>
                </header>
            </MediaQuery>

            {/* MIN 769 MAX 1024px */}

            <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
                <header className="header">
                    <div className="header-buttons" style={headerComponentsMax1024}>
                        <Button style={buttonsStyleMax1024} variant="contained" color="primary" href="/">
                            <HomeIcon/>
                            Home
                        </Button>
                        <Button style={buttonsStyleMax1024} variant="contained" color="primary" href="#">
                            <InfoIcon/>
                            Sobre
                        </Button>
                        <Button style={buttonsStyleMax1024} variant="contained" color="primary" href="/contato">
                            <PermContactCalendarIcon/>
                            Contato
                        </Button>
                        <input type="search" style={searchFieldMax1024}/>
                        <SearchIcon/>
                    </div>
                </header>
            </MediaQuery>

            {/* MIN 1025 MAX 1200px */}

            <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
                <header className="header">
                    <div className="header-buttons" style={headerComponentsMax1200}>
                        <Button style={buttonsStyleMax1200} variant="contained" color="primary" href="/">
                            <HomeIcon/>
                            Home
                        </Button>
                        <Button style={buttonsStyleMax1200} variant="contained" color="primary" href="#">
                            <InfoIcon/>
                            Sobre
                        </Button>
                        <Button style={buttonsStyleMax1200} variant="contained" color="primary" href="/contato">
                            <PermContactCalendarIcon/>
                            Contato
                        </Button>
                        <input type="search" style={searchFieldMax1200}/>
                        <SearchIcon/>
                    </div>
                </header>
            </MediaQuery>

            {/* MIN 1201px */}

            <MediaQuery minDeviceWidth={1201}>
                <header className="header">
                    <div className="header-buttons" style={headerComponentsMin1201}>
                        <Button style={buttonsStyleMin1201} variant="contained" color="primary" href="/">
                            <HomeIcon/>
                            Home
                        </Button>
                        <Button style={buttonsStyleMin1201} variant="contained" color="primary" href="#">
                            <InfoIcon/>
                            Sobre
                        </Button>
                        <Button style={buttonsStyleMin1201} variant="contained" color="primary" href="/contato">
                            <PermContactCalendarIcon/>
                            Contato
                        </Button>
                        <input type="search" style={searchFieldMin1201}/>
                        <SearchIcon/>
                    </div>
                </header>
            </MediaQuery>



        </div>)
    }
}

export default HeaderBlog;
