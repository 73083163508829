import React from 'react'
import '../App.css';
import Carrosel from '../components/Carrosel';
import Header from '../components/Header';
import Galeria from '../components/Galeria';
import Footer from '../components/Footer';
import Whatsapp from '../components/Whatsapp';


function Main() {

    return (<div className="App">
        <Header/>
        <Carrosel/>
        <Galeria/>
        <Footer/>
        <Whatsapp />
    </div>);
}

export default Main;
