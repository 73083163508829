import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import InfoIcon from '@material-ui/icons/Info';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import MediaQuery from 'react-responsive';

class HeaderImoveis extends Component {
    render() {

        /* ESTILO MIN 320px MAX 480px */

        const buttonsStyleMax480px = {
            padding: "2px",
            margin: "5px 10px 5px 10px",
            width: "auto",
            height: "auto",
            fontSize: "10px",
            justifyContent: "center"
        };

        const headerComponents480px = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /* FIM MAX 480px */

        /* ESTILO MIN 481px MAX 768px */

        const buttonsStyleMax768px = {
            padding: "5px",
            margin: "5px auto 5px auto",
            width: "20vw",
            fontSize: "15px",
            justifyContent: "center",
            height: "auto"
        };

        const headerComponents768px = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /* FIM MAX 768px */

        /* ESTILO MIN 769px MAX 1024px */

        const buttonsStyleMax1024px = {
            padding: "5px",
            margin: "5px auto 5px auto",
            width: "22vw",
            height: "auto",
            fontSize: "20px"
        };

        const headerComponents1024px = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /* FIM MAX 1024px */

        /* ESTILO MIN 1024px MAX 1200px */

        const buttonsStyleMax1200px = {
            padding: "5px",
            margin: "5px",
            width: "22vw",
            height: "auto"
        };

        const headerComponents1200px = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        /* FIM MAX 1200px */

        /* ESTILO MIN 1201px */

        const buttonsStyleMin1201px = {
            padding: "5px",
            margin: "5px",
            width: "22vw",
            height: "auto",
        };

        const headerComponentsMin1201px = {
            display: "flex",
            width: "100%",
            margin: "auto",
            position: "top",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid rgb(28, 89, 207)"
        };

        return (<div>
            {/* MIN 320px MAX 768 */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <header className="header">
                <div className="header-buttons" style={headerComponents480px}>
                    <Button style={buttonsStyleMax480px} variant="contained" color="primary" href="/">
                        <HomeIcon/>
                        Home
                    </Button>
                    <Button style={buttonsStyleMax480px} variant="contained" color="primary" href="/blog">
                        <BookIcon/>
                        Blog
                    </Button>
                    <Button style={buttonsStyleMax480px} variant="contained" color="primary" href="#">
                        <InfoIcon/>
                        Sobre
                    </Button>
                    <Button style={buttonsStyleMax480px} variant="contained" color="primary" href="/contato">
                        <PermContactCalendarIcon/>
                        Contato
                    </Button>
                </div>
            </header>
        </MediaQuery>

        {/* FIM MAX 480 */}

        {/* MIN 481px MAX 768 */}

        <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
        <header className="header">
            <div className="header-buttons" style={headerComponents768px}>
                <Button style={buttonsStyleMax768px} variant="contained" color="primary" href="/">
                    <HomeIcon/>
                    Home
                </Button>
                <Button style={buttonsStyleMax768px} variant="contained" color="primary" href="/blog">
                    <BookIcon/>
                    Blog
                </Button>
                <Button style={buttonsStyleMax768px} variant="contained" color="primary" href="#">
                    <InfoIcon/>
                    Sobre
                </Button>
                <Button style={buttonsStyleMax768px} variant="contained" color="primary" href="/contato">
                    <PermContactCalendarIcon/>
                    Contato
                </Button>
            </div>
        </header>
    </MediaQuery>

    {/* FIM MAX 768px */}

    {/* MIN 769px MAX 1024px*/}

    <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
    <header className="header">
        <div className="header-buttons" style={headerComponents1024px}>
            <Button style={buttonsStyleMax1024px} variant="contained" color="primary" href="/">
                <HomeIcon/>
                Home
            </Button>
            <Button style={buttonsStyleMax1024px} variant="contained" color="primary" href="/blog">
                <BookIcon/>
                Blog
            </Button>
            <Button style={buttonsStyleMax1024px} variant="contained" color="primary" href="#">
                <InfoIcon/>
                Sobre
            </Button>
            <Button style={buttonsStyleMax1024px} variant="contained" color="primary" href="/contato">
                <PermContactCalendarIcon/>
                Contato
            </Button>
        </div>
    </header>
</MediaQuery>

{/* FIM MAX 1024px */}

{/* MIN 1025px MAX 1200px*/}

<MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
<header className="header">
    <div className="header-buttons" style={headerComponents1200px}>
        <Button style={buttonsStyleMax1200px} variant="contained" color="primary" href="/">
            <HomeIcon/>
            Home
        </Button>
        <Button style={buttonsStyleMax1200px} variant="contained" color="primary" href="/blog">
            <BookIcon/>
            Blog
        </Button>
        <Button style={buttonsStyleMax1200px} variant="contained" color="primary" href="#">
            <InfoIcon/>
            Sobre
        </Button>
        <Button style={buttonsStyleMax1200px} variant="contained" color="primary" href="/contato">
            <PermContactCalendarIcon/>
            Contato
        </Button>
    </div>
</header>
</MediaQuery>

{/* FIM MAX 1200px */}

{/* MIN 1201px*/}

<MediaQuery minDeviceWidth={1201}>
<header className="header">
    <div className="header-buttons" style={headerComponentsMin1201px}>
        <Button style={buttonsStyleMin1201px} variant="contained" color="primary" href="/">
            <HomeIcon/>
            Home
        </Button>
        <Button style={buttonsStyleMin1201px} variant="contained" color="primary" href="/blog">
            <BookIcon/>
            Blog
        </Button>
        <Button style={buttonsStyleMin1201px} variant="contained" color="primary" href="#">
            <InfoIcon/>
            Sobre
        </Button>
        <Button style={buttonsStyleMin1201px} variant="contained" color="primary" href="/contato">
            <PermContactCalendarIcon/>
            Contato
        </Button>
    </div>
</header>
</MediaQuery>



</div>)

    }

}

export default HeaderImoveis;
