import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Main from './pages/Main';
import CadaImovel from './pages/CadaImovel';
import MainBlog from './pages/MainBlog';
import Contato from './components/Contato';


function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/imoveis/:id" exact component={CadaImovel} />
                <Route path="/blog" component={MainBlog} />
                <Route path="/contato" component={Contato} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
