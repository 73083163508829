import React, {Component} from 'react';
import MediaQuery from 'react-responsive';

class Galeria extends Component {

    state = {
        imovel: null
    }

    async componentDidMount() {

        try {
            this.setState({imovel: this.props.match.params.id})
        } catch (error) {
            this.setState({error: 'Erro ao recuperar informações do imóvel!'});
        }

    }

    render() {
        /*
                    INICIO
        ESTILO MOBILE DEVICES DE 320px  A  480px

         */

        const mobileMax480Gallery = {
            display: "block",
            justifyContent: "center",
            maxWidth: "100%",
            margin: 0,
            height: "auto",
            textAlign: "center"
        }
        const mobileMax480TitlesFont = {
            textAlign: "center",
            maxWidth: "100%",
            height: "auto"
        }

        const mobileMax480MainCatalogo = {
            margin: "7vh auto 2vh auto",
            fontStyle: "normal",
            fontSize: "20px",
            textAlign: "center"
        }

        const mobileMax480GalleryItem = {
            margin: "1vh 1vw 1vh 1vw",   
            maxWidth: "auto",
            height: "auto",
            backgroundColor: "rgb(240, 240, 240)",
            borderRadius: "10px"
        }

        const mobileMax480DescriptionStyle = {
            fontSize: "12px",
            textAlign: "center",
            width: "100%",
            margin: "5px auto 5px auto",
            fontStyle: "normal",
            fontWeight: "100"
        }

        /*
                    FIM MOBILE 320px A 480px

         */

        /*
                    INICIO
        ESTILO IPADS E TABLETS DEVICES 481px  A 768px

        */

        const tabletMax768Gallery = {
            display: "flex",
            flexFlow: "column",
            maxWidth: "100%",
            height: "auto",
            margin: "5vh 2vw 3vh 2vw",
            padding: "auto",
            textAlign: "center"
        }

        const tabletMax768CatalogoFont = {
            margin: "10vh auto auto auto",
            fontStyle: "normal",
            fontSize: "40px",
            textAlign: "center"
        }

        const tabletMax768TitlesFont = {
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
            fontStyle: "normal"
        }

        const tabletMax768DescriptionStyle = {
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
            margin: "2px",
            fontStyle: "normal",
            fontWeight: "200"
        }

        const tabletMax768GalleryItem = {
            display: "block",
            margin: "1vh",
            height: "100%",
            width: "auto",
            backgroundColor: "rgb(240, 240, 240)",
            borderRadius: "10px",
            paddingBottom: "15px"
        }

        /*
                    FIM TELAS TABLET 481px  A 768px

         */

        /*
                     INICIO
         ESTILO TELAS PEQUENAS E LAPTOPS 769px  A  1024px

         */

        const laptopsMax1024Gallery = {
            display: "grid",
            maxWidth: "100%",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridAutoFlow: "row",
            height: "auto",
            margin: "3vh auto 3vh auto",
            padding: "auto",
            textAlign: "center"
        }

        const laptopsMax1024CatalogoFont = {
            margin: "6vh auto auto auto",
            fontStyle: "normal",
            fontSize: "40px",
            textAlign: "center"
        }

        const laptopsMax1024TitlesFont = {
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
            fontStyle: "normal"
        }

        const laptopsMax1024DescriptionStyle = {
            fontSize: "15px",
            textAlign: "center",
            width: "100%",
            margin: "2px",
            fontStyle: "normal",
            fontWeight: "200"
        }

        const laptopsMax1024GalleryItem = {
            display: "block",
            margin: "1vh 1vh 3vh 1vh",
            backgroundColor: "rgb(240, 240, 240)",
            borderRadius: "10px",
            paddingBottom: "25px"
        }

        /*
                     INICIO
         ESTILO TELAS DESKTOP 1025px  A  1200px

         */

        const laptopsMax1200Gallery = {
            display: "grid",
            maxWidth: "100%",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridAutoFlow: "row",
            height: "auto",
            margin: "3vh auto 3vh auto",
            padding: "auto",
            textAlign: "center"
        }

        const laptopsMax1200CatalogoFont = {
            margin: "5vh auto auto auto",
            fontStyle: "normal",
            fontSize: "40px",
            textAlign: "center"
        }

        const laptopsMax1200TitlesFont = {
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
            fontStyle: "normal"
        }

        const laptopsMax1200DescriptionStyle = {
            fontSize: "15px",
            textAlign: "center",
            width: "100%",
            margin: "2px",
            fontStyle: "normal",
            fontWeight: "200"
        }

        const laptopsMax1200GalleryItem = {
            display: "block",
            margin: "1vh 1vh 3vh 1vh",
            backgroundColor: "rgb(240, 240, 240)",
            borderRadius: "10px",
            paddingBottom: "25px"
        }

        /*
                     INICIO
         ESTILO TELAS DESKTOP MIN 1201px

         */

        const desktopMin1201Gallery = {
            display: "grid",
            maxWidth: "100%",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridAutoFlow: "row",
            height: "auto",
            margin: "3vh 1vw 3vh 1vw",
            padding: "auto",
            textAlign: "center"
        }

        const desktopMin1201CatalogoFont = {
            margin: "6vh auto auto auto",
            fontStyle: "normal",
            fontSize: "40px",
            textAlign: "center"
        }

        const desktopMin1201TitlesFont = {
            fontSize: "20px",
            textAlign: "center",
            maxWidth: "35vw",
            height: "7vh",
            fontStyle: "normal"
        }

        const desktopMin1201DescriptionStyle = {
            fontSize: "15px",
            textAlign: "center",
            maxWidth: "100vw",
            margin: "2px",
            fontStyle: "normal",
            fontWeight: "200"
        }

        const desktopMin1201GalleryItem = {
            maxWidth: "100%",
            display: "block",
            margin: "1vh 1vh 3vh 1vh",
            backgroundColor: "rgb(240, 240, 240)",
            borderRadius: "10px",
            paddingBottom: "25px"
        }

        return (<div>

            {/* MOBILE DEVICES DE 320 A 480px */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <h2 style={mobileMax480MainCatalogo}>Catálogo de Imóveis</h2>
                <div style={mobileMax480Gallery}>
                    <div className="gallery" style={mobileMax480GalleryItem}>
                        <h2 style={mobileMax480TitlesFont}>Casa 3 quartos - Panorâmico</h2>
                        <h3 style={mobileMax480DescriptionStyle}>4 Quartos | 2 Banheiros | 1 Vaga de Garagem
                        </h3>
                        <a target="_parent" href="/imoveis/1">
                            <img src="/imgs/imoveis/1/A_1.jpg" alt="Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem" width="400" height="350"/></a>
                        <h3>Consulta</h3>
                    </div>
                    <div className="gallery" style={mobileMax480GalleryItem}>
                        <h2 style={mobileMax480TitlesFont}>Lançamento Abacateiro 55</h2>
                        <h3 style={mobileMax480DescriptionStyle}>Lançamento Abacateiro 55</h3>
                        <a target="_parent" href="/imoveis/2">
                            <img src="/imgs/imoveis/2/L_4.jpg" alt="Imagem computadorizada de um sobrado na cor bege de dois andares com varanda" width="400" height="350"/></a>
                        <h3>Consulta</h3>
                    </div>
                    <div className="gallery" style={mobileMax480GalleryItem}>
                        <h2 style={mobileMax480TitlesFont}>Terreno Ferraz 700 m²</h2>
                        <h3 style={mobileMax480DescriptionStyle}>Terreno Ferraz 700 m²</h3>
                        <a target="_parent" href="/imoveis/3">
                            <img src="/imgs/imoveis/3/B_5.jpg" alt="Terreno com gramado, há um um automovel no começo do terreno e imoveis ao redor" width="400" height="350"/></a>
                        <h3>Consulta</h3>
                    </div>
                    <div className="gallery" style={mobileMax480GalleryItem}>
                        <h2 style={mobileMax480TitlesFont}>Terreno Vigia</h2>
                        <h3 style={mobileMax480DescriptionStyle}>Terreno Vigia</h3>
                        <a target="_parent" href="/imoveis/4">
                            <img src="/imgs/imoveis/4/C_1.jpg" alt="Terreno grande com uma rua na frente. Ao fundo é possivel visualizar a baia de Garopaba em um dia ensolarado" width="400" height="350"/></a>
                        <h3>Consulta</h3>
                    </div>
                </div>
            </MediaQuery>

            {/* FIM MOBILE */}

            {/* *********** */}

            {/* TABLETS/TELAS 481 A 768px */}

            <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
                <h1 style={tabletMax768CatalogoFont}>Catálogo de Imóveis</h1>
                <div style={tabletMax768Gallery}>
                    <div className="gallery" style={tabletMax768GalleryItem}>
                        <h2 style={tabletMax768TitlesFont}>Casa frente mar</h2>
                        <h6 style={tabletMax768DescriptionStyle}>4 Quartos | 2 Banheiros | 1 Vaga de Garagem
                        </h6>
                        <a target="_parent" href="/imoveis/1">
                            <img src="/imgs/imoveis/1/A_1.jpg" alt="Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem" width="450" height="450"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={tabletMax768GalleryItem}>
                        <h2 style={tabletMax768TitlesFont}>Lançamento Abacateiro 55</h2>
                        <h6 style={tabletMax768DescriptionStyle}>Lançamento Abacateiro 55</h6>
                        <a target="_parent" href="/imoveis/2">
                            <img src="/imgs/imoveis/2/L_4.jpg" alt="Imagem computadorizada de um sobrado na cor bege de dois andares com varanda" width="450" height="450"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={tabletMax768GalleryItem}>
                        <h2 style={tabletMax768TitlesFont}>Terreno Ferraz 700 m²</h2>
                        <h6 style={tabletMax768DescriptionStyle}>Terreno Ferraz 700 m²</h6>
                        <a target="_parent" href="/imoveis/3">
                            <img src="/imgs/imoveis/3/B_5.jpg" alt="Terreno com gramado, há um um automovel no começo do terreno e imoveis ao redor" width="450" height="450"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={tabletMax768GalleryItem}>
                        <h2 style={tabletMax768TitlesFont}>Terreno Vigia</h2>
                        <h6 style={tabletMax768DescriptionStyle}>Terreno Vigia</h6>
                        <a target="_parent" href="/imoveis/4">
                            <img src="/imgs/imoveis/4/C_1.jpg" alt="Terreno grande com uma rua na frente. Ao fundo é possivel visualizar a baia de Garopaba em um dia ensolarado" width="450" height="450"/></a>
                        <h2>Consulta</h2>
                    </div>
                </div>
            </MediaQuery>

            {/* FIM TABLET */}

            {/* *********** */}

            {/* LAPTOPS/TELAS 769 A 1024px */}

            <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
                <h1 style={laptopsMax1024CatalogoFont}>Catálogo de Imóveis</h1>
                <div style={laptopsMax1024Gallery}>
                    <div className="gallery" style={laptopsMax1024GalleryItem}>
                        <h2 style={laptopsMax1024TitlesFont}>Casa frente mar</h2>
                        <h6 style={laptopsMax1024DescriptionStyle}>4 Quartos | 2 Banheiros | 1 Vaga de Garagem
                        </h6>
                        <a target="_parent" href="/imoveis/1">
                            <img src="/imgs/imoveis/1/A_1.jpg" alt="Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1024GalleryItem}>
                        <h2 style={laptopsMax1024TitlesFont}>Lançamento Abacateiro 55</h2>
                        <h6 style={laptopsMax1024DescriptionStyle}>Lançamento Abacateiro 55</h6>
                        <a target="_parent" href="/imoveis/2">
                            <img src="/imgs/imoveis/2/L_4.jpg" alt="Imagem computadorizada de um sobrado na cor bege de dois andares com varanda" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1024GalleryItem}>
                        <h2 style={laptopsMax1024TitlesFont}>Terreno Ferraz 700 m²</h2>
                        <h6 style={laptopsMax1024DescriptionStyle}>Terreno Ferraz 700 m²</h6>
                        <a target="_parent" href="/imoveis/3">
                            <img src="/imgs/imoveis/3/B_5.jpg" alt="Terreno com gramado, há um um automovel no começo do terreno e imoveis ao redor" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1024GalleryItem}>
                        <h2 style={laptopsMax1024TitlesFont}>Terreno Vigia</h2>
                        <h6 style={laptopsMax1024DescriptionStyle}>Terreno Vigia</h6>
                        <a target="_parent" href="/imoveis/4">
                            <img src="/imgs/imoveis/4/C_1.jpg" alt="Terreno grande com uma rua na frente. Ao fundo é possivel visualizar a baia de Garopaba em um dia ensolarado" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                </div>
            </MediaQuery>

            {/* FIM LAPTOPS */}

            {/* *********** */}

            {/* DESKTOP 1025px A 1200px */}

            <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
                <h1 style={laptopsMax1200CatalogoFont}>Catálogo de Imóveis</h1>
                <div style={laptopsMax1200Gallery}>
                    <div className="gallery" style={laptopsMax1200GalleryItem}>
                        <h2 style={laptopsMax1200TitlesFont}>Casa frente mar</h2>
                        <h6 style={laptopsMax1200DescriptionStyle}>4 Quartos | 2 Banheiros | 1 Vaga de Garagem
                        </h6>
                        <a target="_parent" href="/imoveis/1">
                            <img src="/imgs/imoveis/1/A_1.jpg" alt="Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1200GalleryItem}>
                        <h2 style={laptopsMax1200TitlesFont}>Lançamento Abacateiro 55</h2>
                        <h6 style={laptopsMax1200DescriptionStyle}>Lançamento Abacateiro 55</h6>
                        <a target="_parent" href="/imoveis/2">
                            <img src="/imgs/imoveis/2/L_4.jpg" alt="Imagem computadorizada de um sobrado na cor bege de dois andares com varanda" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1200GalleryItem}>
                        <h2 style={laptopsMax1200TitlesFont}>Terreno Ferraz 700 m²</h2>
                        <h6 style={laptopsMax1200DescriptionStyle}>Terreno Ferraz 700 m²</h6>
                        <a target="_parent" href="/imoveis/3">
                            <img src="/imgs/imoveis/3/B_5.jpg" alt="Terreno com gramado, há um um automovel no começo do terreno e imoveis ao redor" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={laptopsMax1200GalleryItem}>
                        <h2 style={laptopsMax1200TitlesFont}>Terreno Vigia</h2>
                        <h6 style={laptopsMax1200DescriptionStyle}>Terreno Vigia</h6>
                        <a target="_parent" href="/imoveis/4">
                            <img src="/imgs/imoveis/4/C_1.jpg" alt="Terreno grande com uma rua na frente. Ao fundo é possivel visualizar a baia de Garopaba em um dia ensolarado" width="300" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                </div>
            </MediaQuery>

            {/* FIM LAPTOPS */}

            {/* *********** */}

            {/* DESKTOP MIN 1201px */}

            <MediaQuery minDeviceWidth={1201}>
                <h1 style={desktopMin1201CatalogoFont}>Catálogo de Imóveis</h1>
                <div style={desktopMin1201Gallery}>
                    <div className="gallery" style={desktopMin1201GalleryItem}>
                        <h2 style={desktopMin1201TitlesFont}>Casa frente mar</h2>
                        <h6 style={desktopMin1201DescriptionStyle}>4 Quartos | 2 Banheiros | 1 Vaga de Garagem
                        </h6>
                        <a target="_parent" href="/imoveis/1">
                            <img src="/imgs/imoveis/1/A_1.jpg" alt="Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem" width="350" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={desktopMin1201GalleryItem}>
                        <h2 style={desktopMin1201TitlesFont}>Lançamento Abacateiro 55</h2>
                        <h6 style={desktopMin1201DescriptionStyle}>Lançamento Abacateiro 55</h6>
                        <a target="_parent" href="/imoveis/2">
                            <img src="/imgs/imoveis/2/L_4.jpg" alt="Imagem computadorizada de um sobrado na cor bege de dois andares com varanda" width="350" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={desktopMin1201GalleryItem}>
                        <h2 style={desktopMin1201TitlesFont}>Terreno Ferraz 700 m²</h2>
                        <h6 style={desktopMin1201DescriptionStyle}>Terreno Ferraz 700 m²</h6>
                        <a target="_parent" href="/imoveis/3">
                            <img src="/imgs/imoveis/3/B_5.jpg" alt="Terreno com gramado, há um um automovel no começo do terreno e imoveis ao redor" width="350" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                    <div className="gallery" style={desktopMin1201GalleryItem}>
                        <h2 style={desktopMin1201TitlesFont}>Terreno Vigia</h2>
                        <h6 style={desktopMin1201DescriptionStyle}>Terreno Vigia</h6>
                        <a target="_parent" href="/imoveis/4">
                            <img src="/imgs/imoveis/4/C_1.jpg" alt="Terreno grande com uma rua na frente. Ao fundo é possivel visualizar a baia de Garopaba em um dia ensolarado" width="350" height="300"/></a>
                        <h2>Consulta</h2>
                    </div>
                </div>
            </MediaQuery>

        </div>)

    }
}

export default Galeria;
