import React, {Component} from 'react';
import {render} from 'react-dom';

class Whatsapp extends Component {
    render(){
        const whatsStyle = {
            width: "7vw",
            margin: "25px",
            position: "fixed",
            bottom: 0,
            right: 0
        }
        return (<div>
            <a href="https://api.whatsapp.com/send/?phone=5548999454177&text=Olá, tudo bem ? Entrei no site e fiquei interessado no imóvel"><img style={whatsStyle} src="/imgs/whatsapp.png"/></a>
        </div>)
    }
}

export default Whatsapp;
