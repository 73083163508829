import React, {Component} from 'react';
import InfoCadaImovel from '../components/InfoCadaImovel';
import HeaderImoveis from '../components/HeaderImoveis';
import Whatsapp from '../components/Whatsapp';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import MediaQuery from 'react-responsive';

class CadaImovel extends Component {
    constructor(props) {
        super(props);
        let imovelId = parseInt(this.props.match.params.id)
        let imvDir = require('../components/ImoveisDB');
        let imoveisFromDB = imvDir.default;
        let imovelFromDB;

        for (let i = 0; i < imoveisFromDB.length; i++) {
            if (imoveisFromDB[i].id === imovelId) {
                imovelFromDB = imoveisFromDB[i];
                break;
            }
        }

        this.state = {
            imovel: imovelFromDB
        }

    }

    render() {

        /*
                INICIO
        ESTILO MOBILE DEVICES DE 320px  A  480px
        */

        const carroselStyleMobileMax480 = {
            display: "flex",
            flexFlow: "column",
            margin: 0,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
            borderRadius: "10px"
        }

        const imovelStyleMobileMax480 = {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
            height: "auto"
        }

        const infoItemsMobileMax480 = {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            margin: "auto",
            width: "100%",
            height: "auto"

        }

        const whatsAppStyleMobileMax480 = {
            margin: "10px",
            width: "5vw",
            height: "auto"
        }

        /*
            FIM MOBILE 320px A 480px

         */

        /*
                    INICIO
        ESTILO TABLETS DEVICES 481px  A 768px
        */

        const carroselStyleTabletMax768 = {
            display: "flex",
            flexFlow: "row",
            margin: "5vh auto auto auto",
            width: "100%",
            height: "auto",
            borderRadius: "10px"
        }

        const infoItemsTabletMax768 = {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            width: "auto"

        }

        const whatsAppStyleTabletMax768 = {
            margin: "0",
            width: "100%",
            height: "5vh"
        }

        /*
            FIM TELAS MAX 768px

         */

        /*
                    INICIO
        ESTILO TELAS DEVICES 769px  A 1024px
        */

        const carroselStyleDevicesMax1024 = {
            display: "flex",
            flexFlow: "row",
            margin: "5vh auto auto auto",
            width: "100%",
            height: "auto",
            borderRadius: "10px"
        }

        const imovelStyleDevicesMax1024 = {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            width: "100%",
            height: "auto"
        }

        const infoItemsDevicesMax1024 = {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            margin: "auto",
            justifyContent: "center",
            width: "auto"

        }

        const whatsAppStyleDevicesMax1024 = {
            margin: "10px",
            width: "2vw"
        }

        /*
            FIM TELAS MAX 1024px

         */

        /*
                    INICIO
        ESTILO TELAS MAX 1200px
        */

        const carroselStyleDevicesMax1200 = {
            display: "flex",
            flexFlow: "row",
            margin: "auto",
            width: "100%",
            height: "auto",
            borderRadius: "10px"
        }

        const imovelStyleDevicesMax1200 = {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            width: "100%",
            height: "auto"
        }

        const infoItemsDevicesMax1200 = {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            margin: "auto",
            justifyContent: "center",
            width: "auto"

        }

        const whatsAppStyleDevicesMax1200 = {
            margin: "10px",
            width: "2vw"
        }

        /*
            FIM TELAS MAX 1200px

         */

        /*
                INICIO
        ESTILO TELAS MIN 1201px
        */

        const carroselStyleDevicesMin1201 = {
            display: "flex",
            flexFlow: "column",
            width: "100%",
            height: "auto",
            borderRadius: "10px"
        }

        const imovelStyleDevicesMin1201 = {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "50%"
        }

        const infoItemsDevicesMin1201 = {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            margin: "auto",
            justifyContent: "center",
            width: "auto"

        }

        const whatsAppStyleDevicesMin1201 = {
            margin: "10px",
            width: "2vw"
        }

        return (<div>
            <HeaderImoveis/>
            <br/> {/* MOBILE DEVICES DE 320 A 480px */}

            <MediaQuery minDeviceWidth={320} maxDeviceWidth={480}>
                <div id="carroselImovel" style={imovelStyleMobileMax480}>
                    <div className="carrosel" style={carroselStyleMobileMax480}>
                        <AwesomeSlider media={this.state.imovel
                                ? this.state.imovel.media
                                : "-"}/>
                    </div>
                    <div id="infoItems" style={infoItemsMobileMax480}>
                        <InfoCadaImovel imovelParam={this.state.imovel}/>
                    </div>
                    <Whatsapp style={whatsAppStyleMobileMax480}/>
                </div>
            </MediaQuery>

            {/* FIM MOBILE 320px A 480px */}

            {/*             INICIO
             ESTILO TABLETS DEVICES 481px  A 768px */
            }

            <MediaQuery minDeviceWidth={481} maxDeviceWidth={768}>
                <div id="carroselImovel">
                    <div className="carrosel" style={carroselStyleTabletMax768}>
                        <AwesomeSlider media={this.state.imovel
                                ? this.state.imovel.media
                                : "-"}/>
                    </div>
                    <div id="infoItems" style={infoItemsTabletMax768}>
                        <InfoCadaImovel imovelParam={this.state.imovel}/>
                    </div>
                    <Whatsapp style={whatsAppStyleTabletMax768}/>
                </div>
            </MediaQuery>

            {/* FIM MOBILE MAX 768px */}

            {/*             INICIO
              ESTILO DEVICES MIN 769px E MAX 1024px */
            }

            <MediaQuery minDeviceWidth={769} maxDeviceWidth={1024}>
                <div id="carroselImovel" style={imovelStyleDevicesMax1024}>
                    <div className="carrosel" style={carroselStyleDevicesMax1024}>
                        <AwesomeSlider media={this.state.imovel
                                ? this.state.imovel.media
                                : "-"}/>
                    </div>
                    <div id="infoItems" style={infoItemsDevicesMax1024}>
                        <InfoCadaImovel imovelParam={this.state.imovel}/>
                    </div>
                    <Whatsapp style={whatsAppStyleDevicesMax1024}/>
                </div>
            </MediaQuery>

            {/* FIM DEVICE MAX 1024px */}

            {/*             INICIO
               ESTILO DEVICES MIN 1025px E MAX 1200px */
            }

            <MediaQuery minDeviceWidth={1025} maxDeviceWidth={1200}>
                <div id="carroselImovel" style={imovelStyleDevicesMax1200}>
                    <div className="carrosel" style={carroselStyleDevicesMax1200}>
                        <AwesomeSlider media={this.state.imovel
                                ? this.state.imovel.media
                                : "-"}/>
                    </div>
                    <div id="infoItems" style={infoItemsDevicesMax1200}>
                        <InfoCadaImovel imovelParam={this.state.imovel}/>
                    </div>
                    <Whatsapp style={whatsAppStyleDevicesMax1200}/>
                </div>
            </MediaQuery>

            {/* FIM DEVICE MAX 1200px */}

            {/*             INICIO
                ESTILO DEVICES MIN 1201px */
            }

            <MediaQuery minDeviceWidth={1201}>
                <div id="carroselImovel" style={imovelStyleDevicesMin1201}>
                    <div className="carrosel" style={carroselStyleDevicesMin1201}>
                        <AwesomeSlider media={this.state.imovel
                                ? this.state.imovel.media
                                : "-"}/>
                    </div>
                    <div id="infoItems" style={infoItemsDevicesMin1201}>
                        <InfoCadaImovel imovelParam={this.state.imovel}/>
                    </div>
                    <Whatsapp style={whatsAppStyleDevicesMin1201}/>
                </div>
            </MediaQuery>

        </div>)
    }
}

export default CadaImovel;
