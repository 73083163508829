import React, {Component} from 'react';
import SwiftSlider from 'react-swift-slider'

class Carrosel extends Component {

    render() {
        const carroselStyle = {
            margin: "1vh auto auto auto"
        };
        const data = [
            {
                'id': '1',
                'src': "/imgs/imoveis/1/A_2.jpg",
                'alt': "Casa na cor bege com aberturas na cor branca. Os muros possuem vidro, assim como a entrada da garagem"
            },
            {
                'id': '2',
                'src': "/imgs/imoveis/2/L_4.jpg",
                'alt': "Sobrado com dois andares, as imagens são ficticias extraidas de projetos  de arquitetura. Sao exibidas areas urbanizadas com pessoas. Terreno plano"
            },{
                
                'id': '4',
                'src': "/imgs/imoveis/4/C_1.jpg",
                'alt': "Terreno grande com uma rua na frente. Ao fundo é possivea de Garopaba em um dia ensolarado"
                
            }
        ];
        
        return (<div className="carrosel" style={carroselStyle}>
            <SwiftSlider data={data}/>
        </div>);
    }
}
export default Carrosel;
