import React, {Component} from 'react';
import HeaderBlog from './HeaderBlog';

class Blog extends Component {
    render() {
        const generalBlogStyle = {
            color: "rgb(28, 89, 207)",
            justifyContent: "center",
            margin: "25px"
        }

        return (<div>
            <HeaderBlog/>
            <h1 style={generalBlogStyle}>Em breve...</h1>
        </div>)
    }
}

export default Blog;
